import styled from "styled-components"
import { Box, CircularProgress, Select } from "@mui/material"
import FluidImage from "../fluid-image/fluid-image"
import CustomInput from "../custom-input/custom-input.component"
import CustomButton from "../custom-button/custom-button.component"
import { Close } from "@mui/icons-material"
import FluidBgImage from "../fluid-bg-image/fluid-bg-image"
import Logo from "../../assets/icons/blackLogo.svg"

export const ModalContainer = styled(Box)`
  max-width: 1100px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  display: flex;
`

export const BgImage = styled(FluidBgImage)`
  display: none;

  @media (min-width: 700px) {
    display: block;
    min-width: 200px;
  }

  @media (min-width: 900px) {
    display: block;
    min-width: 380px;
  }
`

export const SuccessContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  p {
    font-size: ${({ theme }) => theme.typography.pxToRem(30)};
    text-align: center;
  }
`

export const FormContainer = styled(Box)`
  width: 100%;
  padding: 2.5em 1.5em 4.5em;
  position: relative;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 2.5em 7.5em 4em;
  }

  form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`

export const RadiusLogo = styled(Logo)`
  width: 100px;
  height: 40px;

  display: block;
  margin: auto;
`

export const FormSpinner = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0,0,0,0.1);
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`

export const CustomSpinner = styled(CircularProgress)`
  color: black;
  width: 60px !important;
  height: 60px !important;
`

export const InputName = styled.label`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  line-height: 16px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
`

export const FormInput = styled(CustomInput)`
  textarea,
  input {
    color: black;
  }
  input {
    padding: 16px 8px 16px 16px;
  }

  .MuiInputLabel-outlined,
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.15) !important;
  }
`

export const CustomSelect = styled(Select)`
  width: 100%;
  margin-bottom: 1em;
  color: black;

  .MuiInputLabel-outlined,
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.15) !important;
  }
`

export const Button = styled(CustomButton)`
  margin-top: 1.4em;
  padding: 0.5em 2em;
  min-height: unset !important;
`
export const CloseIcon = styled(Close)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  width: 28px;
  cursor: pointer;
  opacity: 0.5;
  margin: auto;
`
export const InputsContainer = styled(Box)``

export const PrivacyMessage = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  font-weight: 600;
`
