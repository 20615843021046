import React, { useEffect } from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import HeroBanner from "../../layouts/HeroBanner"
import BookContentSection from "../../components/book-content-section/book-content-section.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import Box from "@mui/material/Box"
import moment from "moment"
import { isBrowser } from "../../utils"
import DownloadFormModal from "../../components/download-form-modal/download-form-modal.component"
export const query = graphql`
  query PostQuery($id: String!) {
    wpPost(id: { eq: $id }) {
      slug
      uri
      title
      date
      featuredImage {
        node {
          altText
          sourceUrl
          title
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      content
      postsFields {
        bookTitle
        relatedBook {
          ... on WpBook {
            id
            booksFields {
              amazonLink
              barnesNobleLink
            }
            title
          }
        }
        pdfFile {
          altText
          publicUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
    }
  }
`

const Post = ({ data }) => {
  const {
    postsFields: { relatedBook, bookTitle, pdfFile },
    title,
    date,
    content,
    featuredImage,
    seo,
    slug,
  } = data.wpPost
  const formatDate = date
    ? moment(date, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY")
    : ""
  const canonical = `${process.env.GATSBY_WEBSITE_URL}/news/${slug}/`
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (isBrowser()) {
      const handleClick = event => {
        setOpen(true)
      }
      const elements = document.getElementsByClassName("modal-link")
      Array.from(elements).forEach(function (element) {
        element.addEventListener("click", handleClick)
      })

      return () => {
        Array.from(elements).forEach(function (element) {
          element.removeEventListener("click", handleClick)
        })
      }
    }
  }, [])

  return (
    <Layout seo={{ ...seo, canonical }}>
      <HeroBanner
        image={featuredImage && featuredImage.node}
        title={title}
        className="blur dark"
        tagText="Radius News"
        tagClass="light"
      />
      <BookContentSection
        content={content}
        date={formatDate}
        amazonLink={relatedBook?.booksFields?.amazonLink}
        barnesNobleLink={relatedBook?.booksFields?.barnesNobleLink}
        featuredImage={featuredImage}
        title={
          relatedBook
            ? `<h2>Want to buy <i>${bookTitle ?? relatedBook.title}</i>?</h2>`
            : ""
        }
      />

      {pdfFile?.publicUrl && (
        <DownloadFormModal
          open={open}
          handleClose={handleClose}
          urlDownload={pdfFile?.publicUrl}
        />
      )}

      <Box
        style={{
          textAlign: "center",
          backgroundColor: "#f2f0ec",
          paddingBottom: "6em",
        }}
      >
        <CustomButton href="/news/" className="darkBorder">
          Return to News
        </CustomButton>
      </Box>
    </Layout>
  )
}

export default Post
