import styled from "styled-components"
import TextField from "@mui/material/TextField"

export const Wrapper = styled(TextField)`
  color: white;
  .MuiInputBase-root {
    color: white;
  }

  input {
    padding: 9.5px 1.3em;
    color: white;
  }

  .MuiInputLabel-outlined,
  .MuiOutlinedInput-notchedOutline {
    color: white !important;
    border-color: white !important;
  }
`
