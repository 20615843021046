import styled from "styled-components"

export const Wrapper = styled.form`
  position: relative;
  .MuiInputBase-formControl {
    margin-bottom: 1em;
  }

  .MuiButtonBase-root {
    min-height: 56px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`