import React from "react"
import * as S from "./custom-input.styles.jsx"
import InputAdornment from "@mui/material/InputAdornment"
import FormError from "../form-error/form-error.component"

const CustomInput = props => {
  const {
    children,
    name,
    className,
    errors,
    customError,
    isRequired,
    rows,
    register,
    label,
    type = "text",
  } = props
  return (
    <>
      <S.Wrapper
        id="standard-adornment-password"
        fullWidth
        multiline={!!rows}
        rows={rows}
        name={name}
        required={!!isRequired}
        className={className}
        placeholder={label}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{children}</InputAdornment>
          ),
        }}
        variant="outlined"
        color="secondary"
        // label={label}
        type={type}
        {...register(name, { required: !!isRequired })}
      />
      {errors && errors[name] && (
        <FormError
          errorMessage={customError ? customError : "This is a required field"}
        />
      )}
    </>
  )
}
export default CustomInput
