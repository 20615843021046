import React, { useEffect, useState } from "react"
import { Dialog, MenuItem, Modal, Typography } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { useForm } from "react-hook-form"
import { contactFormApi } from "../../apis/apis"

import CustomForm from "../custom-form/custom-form.component"

import * as S from "./download-form-modal.styles"
import { isBrowser } from "../../utils"
import { RadiusLogo } from "./download-form-modal.styles"

const DownloadFormModal = ({ open, handleClose, urlDownload }) => {
  const { register, handleSubmit, control, watch, errors, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const staticQuery = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "modal-image.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)

  const [relationship, setRelationship] = useState("Author")
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState()

  useEffect(() => {
    setValue("subject", "Author")
  }, [])

  const onChangeSelect = e => {
    setRelationship(e.target.value)
    setValue("subject", e.target.value)
  }

  const onSubmit = data => {
    setErrorMessage("")
    setIsLoading(true)

    const form = new FormData()
    form.append("your-name", data.name)
    form.append("your-email", data.email)
    form.append("your-subject", data.subject)
    form.append("your-message", data.othersubject ?? "")

    contactFormApi
      .post(`/27541/feedback`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setIsLoading(false)
        if (response.data) {
          if (response.data.status === "mail_sent") {
            setSuccessMessage(response.data.message)
            donwloadFileEvent()
            if (isBrowser()) {
              window.gtag("event", "form_submission", {
                event_category: "Form",
                event_action: "Submit",
                event_label: "Contact form",
              })
            }
          } else {
            setErrorMessage(response.data.message)
          }
        }
      })
      .catch(err => err)
  }

  const donwloadFileEvent = () => {
    if (isBrowser()) {
      const element = document.createElement("a")
      console.log(urlDownload)
      element.setAttribute("href", urlDownload)
      element.setAttribute("target", "_blank")

      element.style.display = "none"

      document.body.appendChild(element)

      element.click()
      document.body.removeChild(element)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <S.ModalContainer>
        <S.CloseIcon onClick={handleClose} />
        <S.BgImage img={staticQuery.img} />
        <S.FormContainer>
          <S.RadiusLogo />
          {isLoading && (
            <S.FormSpinner>
              <S.CustomSpinner />
            </S.FormSpinner>
          )}
          {successMessage ? (
            <S.SuccessContainer>
              <Typography color="primary">
                <b>{successMessage}</b>
              </Typography>
            </S.SuccessContainer>
          ) : (
            <CustomForm onSubmit={handleSubmit(onSubmit)}>
              <S.InputsContainer>
                <S.PrivacyMessage>
                  To receive the white paper, please fill out this form. We will
                  not share your information with third parties
                </S.PrivacyMessage>

                <S.InputName>Name</S.InputName>
                <S.FormInput
                  register={register}
                  label="Your name"
                  isRequired
                  errors={errors}
                  name="name"
                />

                <S.InputName>Email</S.InputName>
                <S.FormInput
                  register={register}
                  label="Your email"
                  isRequired
                  errors={errors}
                  name="email"
                />
                <S.InputName>
                  How are you connected to the publishing industry?
                </S.InputName>
                <S.CustomSelect
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={relationship}
                  onChange={onChangeSelect}
                  required
                >
                  <MenuItem value="Author" selected>
                    Author
                  </MenuItem>
                  <MenuItem value="Publisher">Publisher</MenuItem>
                  <MenuItem value="Literary Agent">Literary Agent</MenuItem>
                  <MenuItem value="Publishing Services Provider">
                    Publishing Services Provider
                  </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </S.CustomSelect>
                {relationship === "Other" && (
                  <S.FormInput
                    register={register}
                    isRequired
                    errors={errors}
                    name="othersubject"
                    multiline
                    rows={5}
                  />
                )}
                {errorMessage && (
                  <Typography color="primary" style={{ marginTop: "1em" }}>
                    <b>{errorMessage}</b>
                  </Typography>
                )}

                <S.Button type="submit" fullWidth>
                  Send
                </S.Button>
              </S.InputsContainer>
            </CustomForm>
          )}
        </S.FormContainer>
      </S.ModalContainer>
    </Dialog>
  )
}

export default DownloadFormModal
