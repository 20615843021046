import styled from "styled-components"
import { Typography } from "@mui/material" 

export const ErrorMessage = styled(Typography)`
  display: block;
  //position: absolute;
  color: #f44336 !important;
  font-size: 0.875rem;
  margin-bottom: 0 !important;
  top: -8px;
  position: relative;
`