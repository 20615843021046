import React from "react"
import * as S from "./custom-form.styles.jsx"

const CustomForm = ({ children, dataFormId, dataPortalId, onSubmit }) => {
  return (
    <S.Wrapper
      data-form-id={dataFormId}
      data-portal-id={dataPortalId}
      onSubmit={onSubmit}
    >
      {children}
    </S.Wrapper>
  )
}
export default CustomForm